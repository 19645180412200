.footer {
  background: #003723;
}
.foocol {
  border-top-right-radius: 70px !important;
  background: url(/src/img/home/footer.png);
  background-size: cover;
  padding: 40px 20px;
  background-repeat: no-repeat;
}
.ftr-list {
	list-style: none;
	padding: 0;
	margin: 15px 0;
}
.ftr-list li {
	display: inline-block;
	padding: 5px 14px;
}
.ftr-list {
  text-align: center;
}
.ftr-list li a {
  color: #404040;
  text-decoration: unset;
  font-size: 16px;
  font-weight: 500;
}
.ftr-list li a:hover {
  color: #3ab8eb;
}
.footer figure {
  text-align: center;
  margin: 0 auto !important;
}
.footer figure img {
  text-align: center;
  margin: 0 auto !important;
}
.social-icons {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.social-icons li {
  padding: 0 10px;
  display: inline-block;
}
.social-icons li i {
  color: #404040;
  font-size: 25px;
}
.social-icons li i:hover {
  color: #3ab8eb;
  font-size: 25px;
}
.footer p {
  text-align: center;
  color: #404040;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px !important;
  margin-bottom: 0 !important;
}
/* scroll to top */
.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: #3ab8eb;
	color: white;
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	cursor: pointer;
	z-index: 1000;
	transition: opacity 0.3s ease-in-out;
	padding: 12px !important;
}

.scroll-to-top:hover {
	background-color: #3ab8eb;
}

.scroll-to-top:focus {
  outline: none;
}
@media(max-width:767px){
    .footer figure img {
        text-align: center;
        margin: 0 auto !important;
        max-width: 200px !important;
    }
    .foocol {
        padding: 20px;
    }
}
