.aboutpage.page-id-02 {
	background: #124533;
}
.container.homebtm.abtop {
	margin-top: 0 !important;
}
.aboutrow01 {
	padding: 60px 20px;
    position: relative;
}
.abtmdes p {
	color: #fff;
	font-size: 22px;
	font-weight: 500;
	max-width: 1040px;
	margin: auto;
	margin-bottom: 0 !important;
	text-align: center;
}
.abtmdes {
	margin-top: 30px;
}
.homerow02.abow02 {
	padding-bottom: 60px;
}
.aboutrow01::before {
	content: '';
	background: url(/src/img/home/ab_before.png);
	width: 246px;
	height: 440px;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
}
.aboutrow01 .container {
	position: relative;
	z-index: 1;
}
@media(max-width:1200px){
	.aboutrow01::before {
		display: none !important;
	}
}
@media(max-width:767px){
	.abtmdes p {
		font-size: 17px !important;
	}
	.aboutrow01 {
		padding: 40px 20px !important; 

	}
	.homerow02.abow02 {
		padding-bottom: 40px !important;
	}
}
@media(min-width:768px) and (max-width:1024px){
	.abtmdes p {
		font-size: 19px !important;
	}
}
@media(min-width:1025px) and (max-width:1200px){
	.abtmdes p {
		font-size: 20px !important;
	}
}
.abow02 .image-title {
	color: #003723;
	font-family: Montserrat,sans-serif !important;
	font-size: 19px;
	font-weight: 600;
	margin-top: 10px !important;
	text-align: center;
}