.slider-container {
	width: 100%;
	overflow: visible;
	position: relative;
}
.slider-container {
	margin-top: 35px !important;
}
.slick-slide div:first-child {
	margin: 0 10px;
}
.slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.slide {
	margin: 0 !important;
}
/*.slide img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}*/
.slide img {
	width: 100% !important;
	object-position: center;
	cursor: pointer;
}
.image-title {
	text-align: center;
	color: #fff;
	font-size: 19px;
	font-family: "Montserrat", sans-serif !important;
	margin-top: 10px !important;
	font-weight: 600;
}
.slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
 
}

.slick-prev, .slick-next {
	width: 35px;
	height: 35px;
	background-color: #0c8059;
	color: #fff;
	border-radius: 50%;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0px;
	line-height: 1;
}

.slider-container .slick-arrow.slick-prev {
	left: unset !important;
	right: 40px;
}
.slider-container .slick-arrow.slick-next {
	right: 0px;
}
.slick-prev {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.slick-next {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.slick-prev::before {
    content: '\f053';
    font-family: 'FontAwesome'; 
    font-weight: 400; 
}
.slick-prev:focus, .slick-next:focus {
	color: transparent;
	outline: none;
	background-color: #0c8059;
}
.slick-prev:hover, .slick-next:hover {
	color: transparent;
	outline: none;
	background: #3ab8eb;
}
.slider-container .slick-arrow {
	top: -95px !important;
}
.slick-next::before {
	content: '\f054';
	font-family: 'FontAwesome';
	font-weight: 400;
}

@media (max-width: 768px) {
    .slick-prev,
    .slick-next {
        width: 30px;
        height: 30px;
        font-size: 0px !important;
    }
}

@media (max-width: 480px) {
    .slick-prev,
    .slick-next {
        width: 30px;
        height: 30px;
        font-size: 0px !important;
    }
}
@media(max-width:767px){
    .slider-container .slick-arrow.slick-prev {
        left: -10px !important;
        right: unset !important;
        top: 50% !important;
    }
    .slider-container .slick-arrow.slick-next {
        right: -10px !important;
        top: 50% !important;
    }
}


.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.lightbox-content {
    max-width: 90%;
    max-height: 90%;
}


.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1001; /* Ensure the close button is above the overlay */
}



.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.lightbox-content {
    position: relative;
    max-width: 90%;
    text-align: center;
}

.lightbox-image {
    max-width: 100%;
    max-height: 80vh; /* Limit height to 80% of the viewport */
    object-fit: contain; /* Maintain aspect ratio */
}

.lightbox-title {
	text-align: center;
	color: #fff;
	font-size: 19px;
	font-family: "Montserrat", sans-serif !important;
	margin-top: 10px !important;
	font-weight: 600;
}