.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  text-align: center;
}

.lightbox-content img {
  max-width: 80%; /* Adjust width as needed */
  max-height: 80vh; /* Adjust height as needed */
  margin: auto; /* Centers the image */
  display: block; /* Ensures image is treated as a block element */
  transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

.lightbox-content img.zoomed {
  transform: scale(2); /* Increase scale for more zoom */
}

.lightbox-navigation {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	display: flex;
	justify-content: space-between;
	width: 90% !important;
	left: 50%;
	transform: translate(-50%,50%);
}

.nav-button {
  background: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 20px;
}

.nav-button:hover {
	background: #3ab8ebcf;
}
.close {
	position: fixed;
	top: 10px;
	right: 20px;
	font-size: 40px;
	cursor: pointer;
	color: #ddd;
  z-index: 99999;
}
.fa.fa-search-minus {
	color: #ddd !important;
}
.nav-button.zoom {
	position: fixed !important;
	top: 10px !important;
	right: 50px !important;
	left: unset !important;
	padding: 0 !important;
	font-size: 19px !important;
	color: #ddd !important;
}
.lightbox-content h3 {
  color: white; /* Sets title color to white */
  margin: 15px 0; /* Adds margin for spacing */
}

.lightbox-content p {
  color: white; /* Optionally set paragraph text color to white */
}
.lightbox-content img.zoomed {
  cursor: grab; /* Change cursor style when zoomed */
}
.nav-button.zoom {
  background: transparent; /* Make background transparent */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor to pointer */
  color: white; /* Change icon color as needed */
  font-size: 24px; /* Adjust size of the icon */
}
.lightbox-navigation button {
	border-radius: 100%;
	width: 35px;
	height: 35px;
	text-align: center !important;
	background: #3ab8eb;
	color: #fff;
	padding: 0 !important;
}
.lightbox-content p {
	display: none;
}
.lightbox-content h3 {
	margin-top: 10px !important;
	font-size: 22px !important;
}
