.slide.testimonailsbox {
	background: #fff;
}
.ipad {
	padding: 20px;
	margin: 0 !important;
}
.ipad div {
	margin: 0 !important;
}
.testimgblock {
	display: flex;
	align-items: center;
	gap: 10px;
}
.testimgblock > div {
	flex: 1;
	margin-bottom: 20px !important;
}
.testlogo img {
	float: right !important;
}
.authodesignation {
	color: #575756;
	font-weight: 500 !important;
	margin-bottom: 0;
}
.tauthorname {
	color: #003723 !important;
	font-weight: 700 !important;
	margin-bottom: 1px;
}
.testcontent p {
	font-size: 18px;
	font-weight: 500;
	color: #575756;
	line-height: 1.4em;
}
/*
.tauthorname {
	margin-top: 20px !important;
}
.cuh {
	height: 155px;
	overflow: auto;
	scrollbar-width: thin;
	padding-bottom: 20px !important;
} */
.cuh {
	height: 185px;
	padding-bottom: 20px;
	overflow: auto;
	scrollbar-width: thin;
}
.authodesignation {
	min-height: 80px;
}
.tauthorname {
	margin-top: 20px !important;
}
.testimonailsbox img {
	aspect-ratio: unset !important;
	object-fit: unset !important;
	width: auto !important;
}