.pricing.page-id-03 {
	background: #124533;
}
.pricingrow01 {
	padding: 60px 20px;
}
.pricing-page .footer.footerct {
	background: #124533 !important;
}
.pricing-page .homerow4.pricingrow2 {
	padding-bottom: 25px !important;
}
@media(max-width:767px){
	.pricingrow01 {
		padding: 40px 20px !important;
	}
	.homerow4.pricingrow2 {
		padding-bottom: 20px !important;
	}
}
@media(min-width:768px) and (max-width:1024px){

}
@media(min-width:1025px) and (max-width:1200px){

}