.requestdemo.page-id-03 {
  background: #124533;
}
.demorwo01 {
  padding: 60px 20px;
}
.container.rqdemo {
  text-align: center;
  color: #fff;
}
.container.rqdemo h1 {
  font-size: 74px;
  font-weight: 500;
  line-height: 1em;
  margin-bottom: 10px !important;
  text-transform: uppercase !important;
}
.container.rqdemo p {
  font-size: 20px;
  font-weight: 500;
}
.bluetext {
  color: #3ab8eb;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}
.request-a-demo-page .footer.footerct {
  background: #124533;
}
@media (max-width: 767px) {
  .demorwo01 {
    padding: 40px 20px !important;
  }
  .subbtn button {
	background: #3ab8eb;
	font-size: 17px !important;
}
  body .demorwo01 h1 {
    font-size: 30px !important;
    line-height: 1.2em !important;
    margin-bottom: 15px !important;
  }
  .container.rqdemo p {
    font-size: 17px !important;
    font-weight: 500;
  }
  .request-a-demo-page .homerow4.pricingrow2 {
    padding-bottom: 40px !important;
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  body .demorwo01 h1 {
    font-size: 50px !important;
    line-height: 1.2em !important;
    margin-bottom: 15px !important;
  }
  .container.rqdemo p {
    font-size: 18px !important;
    font-weight: 500;
  }
}
