/* */
.dropdown-list li::before {
	content: '';
	background: url(/src/img/home/lmg.png); 
	width: 22px;
	height: 23px;
	position: absolute;
	left: 0;
}
.feature-box.hover-effect:first-child {
	margin-left: 0 !important;
}
.dropdown-list {
	list-style: none !important;
}
.dropdown-list li {
	position: relative;
	padding-left: 30px;
}
.feature-container {
  display: flex;
  justify-content: space-between; /* Even spacing between boxes */
  max-width: 1450px; /* Adjust as needed */
  margin: 0 auto; /* Center the container */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.feature-box {
  position: relative;
  flex: 1 1 28%; /* Show three boxes by default on larger screens */
  margin: 15px;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s, border-color 0.3s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  height: 300px; /* Set a fixed height for uniformity */
  background-color: #fff; /* Light background for better visibility */
}

.hover-effect:hover {
	transform: scale(1.05);
	border-color: #003723;
}

.feature-content {
	padding: 20px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.feature-image {
  margin-bottom: 15px;
}
@media(min-width:1300px){
  .feature-box {
    height: 275px !important;
  }
}
.border-image img {
  max-width: 66px; /* Set max width for images */
  height: auto; /* Maintain aspect ratio */
}
.feature-image {
	margin-left: auto;
	margin-right: auto;
}
.feature-title {
  margin-bottom: 10px; /* Space between title and subtitle */
}

.dropdown-list {
	display: none;
	background-color: #fff;
	padding: 0;
	border-radius: 0 0 10px 10px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	transition: opacity 0.3s ease;
	opacity: 0;
	margin: 0 !important;
	padding: 20px !important;
}
.dropdown-list li {
	font-weight: 500;
}
.border-image {
	border: 2px solid #0c8159;
	border-radius: 100%;
	padding: 20px;
}
.hover-effect:hover .dropdown-list {
  display: block; /* Show the list on hover */
  opacity: 1; /* Fade in */
}

.dropdown-list ul {
  list-style: none; /* Remove default list styling */
  margin: 0; /* Remove margin */
  padding: 15px; /* Add padding inside the dropdown */
}

.feature-title h2 {
	font-size: 32px;
	margin: 10px 0;
	font-weight: 600;
	color: #0c8159;
}

.feature-title h3 {
  font-size: 24px;
  color: #333;
}
.list01 li::before {
	top: 21px !important;
}
li {
  margin: 0; /* Remove margin for list items */
  padding: 5px 0; /* Add some padding for spacing */
  text-align: left; /* Align list items to the left */
}

/* Adding interactive styles for list items */
.dropdown-list li:hover {
	background-color: transparent;
	transition: background-color 0.3s;
}
.dropdown-list li::marker {
	color: #3ab8eb !important;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .feature-box {
    flex: 1 1 45%; /* Adjust to two boxes per row */
  }
}

@media (max-width: 767px) {
  .feature-box {
    flex: 1 1 100%; /* Stack boxes in a single column */
    margin: 10px 0; /* Reduce margin for better fit */
  }
  .dropdown-list li {
    font-size: 16px !important;
  }
  .feature-title h2 {
    font-size: 30px !important;
    margin: 10px 0;
    font-weight: 600;
    color: #0c8159;
  }
}
.nthree {
	display: grid;
	grid-template-columns: repeat(3,1fr);
	gap: 15px;
	display: none !important;
}


.abfourboxes01box01 img {
	margin-bottom: 10px;
	aspect-ratio: 4/3;
	width: 100% !important;
	object-fit: cover;
}
/* */

.homebanner {
  background: url(/src/img/home/ss.png) !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	padding: 200px 20px 150px 20px;
	background-position: 50% 10% !important;
}
.ntwo2 h2 {
	color: #003723;
}
.nthree3 h2 {
	color: #575756;
}.
/* */
/*
.bor1 {
	border-radius: 100%;
	padding: 15px;
	border: 3px solid #0c8159;
	text-align: center !important;
	width: 60px;
	height: 60px;
	position: relative;
	top: -15px;
	left: -32px;
	background: #fff;
}
.bor2 {
	border-radius: 100%;
	padding: 15px;
	border: 3px solid #003723 !important;
	text-align: center !important;
	width: 60px;
	height: 60px;
	position: relative;
	top: -15px;
	left: -32px;
	background: #fff;
}
.bor3 {
	border-radius: 100%;
	padding: 15px;
	border: 3px solid #575756;
	text-align: center !important;
	width: 60px;
	height: 60px;
	position: relative;
	top: -15px;
	left: -32px;
	background: #fff;
} 
.nimagecontet {
	display: grid;
	grid-template-columns: 60px 1fr;
	gap: 20px;
}
.nbox01 {
	box-shadow: 0 5px 24px rgba(0,0,0,.32);
}
.nbox01 {
	background: #fff;
	border-top-left-radius: 50px;
	padding-top: 10px !important;
	border-bottom-right-radius: 50px;
} */
.nbox01 {
	padding: 10px;
}
.nbox01 {
	margin: 25px !important;
}
.nbox01.none::before {
	content: '';
	border-top: 25px solid rgb(12, 129, 89); 
	border-right: 25px solid rgb(12, 129, 89); 
	width: 100%;
	height: 100%;
	position: absolute;
	right: -25px;
	top: -25px;
	border-radius: 65px;
	border-top-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.ntwo2::before {
	content: '';
	border-top: 25px solid rgb(0, 55, 35);
	border-right: 25px solid rgb(0, 55, 35);
	width: 100%;
	height: 100%;
	position: absolute;
	right: -25px;
	top: -25px;
	border-radius: 65px;
	border-top-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.nthree3::before {
	content: '';
	border-top: 25px solid rgb(87, 87, 86) !important;
	border-right: 25px solid rgb(87, 87, 86) !important;
	width: 100%;
	height: 100%;
	position: absolute;
	right: -25px;
	top: -25px;
	border-radius: 65px;
	border-top-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

/*
.nimagecontet1::before {
	content: '';
	background: transparent;
	width: 100%;
	height: 100%;
	position: absolute;
	border: 20px solid green;
	border-left: 0;
	border-bottom: 0;
	border-top-left-radius: 50px;
	border-bottom-right-radius: 50px;
} */
.nbox01 {
	position: relative;
}
.nimagecontet div {
	margin: 20px 0 !important;
}
.ntitle12 ul {
	padding-left: 20px !important;
}
.ntitle12 h2 {
	margin-bottom: 10px;
}
.ntitle12 h2 {
	margin-bottom: 10px;
	line-height: 1.1em !important;
}
.none .ntitle12 h2 {
	color: #0c8159;
}
.none .ntwo2 h2 {
	color: #003723;
}
.none .nthree3 h2 {
	color: #575756;
}


.ntitle12 h3 {
	font-size: 20px;
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	margin-bottom: 10px !important;
}
.ntitle12 li {
	font-size: 17px;
	font-weight: 500 !important;
  margin: 5px 0;
}
.ntitle12 li::marker {
	color: #3ab8eb !important;
}
.nimagecontet {
	position: relative;
}
.nthree {
	display: grid;
	grid-template-columns: repeat(3,1fr);
	gap: 15px;
}

/* boxescss close */
.buttonaling {
	text-align: center;
}

.tog {
	margin-top: 25px !important;
	color: #3ab8eb !important;
	background: transparent !important;
  font-weight: 600 !important;
  padding: 0 !important;
}
.tog:hover {
	color: #003723 !important;
  background: transparent !important;
}
.threecont {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
  justify-content: space-between; /* Add space between columns */
  gap: 20px; /* Gap between columns */
}

.flip-box {
	background-color: transparent;
	perspective: 1000px;
	width: 100%;
	max-width: 100% !important;
	margin: unset !important;
	flex: unset !important;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 200px; /* Adjust height as needed */
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px !important;
	box-shadow: unset !important;
	box-sizing: border-box !important;
}

.flip-box-front {
  background-color: #fff; /* Front background color */
}

.flip-box-back {
  background-color: #f9f9f9; /* Back background color */
  transform: rotateY(180deg);
}

.flip-box-back ul {

  padding: 0;
  margin: 0; /* Ensure no margin on the list */
}

.flip-box-back li {
  margin: 5px 0;
}
.threecont {
	display: grid !important;
	flex-wrap: wrap;
	justify-content: unsafe !important;
	gap: 20px !important;
	grid-template-columns: repeat(3,1fr);
}
/* Responsive adjustments */
@media (max-width: 767px) {
  .flip-box {
      flex: 1 1 100%; /* Full width on smaller screens */
      max-width: none; /* Remove max width restriction */
  }
  .dropdown-list li {
    font-size: 16px !important;
  }
  body .feature-box {
    height: 280px !important;
    background-color: #fff;
  }
  .homebanner {
    padding-bottom: 50px !important;
  }
  .threecont {
    display: grid !important;
    flex-wrap: wrap;
    justify-content: unsafe !important;
    gap: 20px !important;
    grid-template-columns: repeat(1,1fr) !important;
  }
}
@media(min-width:768px) and (max-width:1023px){
  .threecont {
    display: grid !important;
    flex-wrap: wrap;
    justify-content: unsafe !important;
    gap: 20px !important;
    grid-template-columns: repeat(2,1fr) !important;
  }
  body .feature-box {
    position: relative;
    height: 250px !important;
    background-color: #fff;
  }
  .dropdown-list li {
    font-size: 17px !important;
  }
}
@media(min-width:1024px) and (max-width:1170px){
body .flip-box-inner {
    position: relative;
    width: 100%;
    height: 300px !important;
  }
}





.imgc h4 {
	color: #fff;
	padding-bottom: 0 !important;
	margin-bottom: 0 !important;
}
.imc {
	background: rgb(12, 129, 89);
	padding: 10px;
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 10px;
	align-items: center;
}
.imgg img {
	margin-right: 0 !important;
	margin-left: auto !important;
}
.imgg img {
	max-width: 50px;
}
.flip-box-back ul {
	padding-left: 20px;
	margin-top: 10px;
}
.threin {
	flex: 1;
}
.threin h3 {
	margin-bottom: 10px !important;
}
.flip-box-back li::marker {
	color: #3ab8eb !important;
}
.threecont {
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
}
.flip-box-back ul {
	padding-left: 20px;
	margin-top: 10px;
	max-width: 100% !important;
	width: 100% !important;
}
.threin {
	flex: 1 1;
	box-shadow: unset !important;
	padding: 20px 0;
}
.flip-box-inner {
	position: relative;
	width: 100%;
	height: 250px !important;
	transition: transform 0.6s;
	transform-style: preserve-3d;
	background: #fff !important;
	box-shadow: 0 0 16px #0000001f;
}
.threin h3 {
	margin-bottom: 10px !important;
	padding: 0 10px !important;
}
.imc.imc1 {
	background: rgb(0, 55, 35) !important;
}
.imc.imc2 {
	background: rgb(87, 87, 86) !important;
}
.threin ul li {
	padding-bottom: 5px !important;
	font-size: 17px;
	font-weight: 500;
}
.blocktext::after {
	color: #ffffff21;
	border-left: 20px solid;
	border-top: 23px solid transparent;
	border-bottom: 23px solid transparent;
	display: inline-block;
	content: '';
	position: absolute;
	right: -20px;
	top: 0;
}
.blocktext {
	margin-right: 25px !important;
}
.blocktext {
	background: #ffffff21;
	position: relative;
	display: inline-block;
	padding: 2px 10px;
	margin-right: 10px;
}
.container {
  max-width: 1450px;
  margin: auto;
}
.homeinner {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}
.container.homeinner div {
  flex: 1;
}
.threin ul {
	padding-left: 20px;
	margin-top: 10px;
	padding-left: 30px;
	padding-right: 10px;
}
.homerow02 .homerowabouttext {
	margin-bottom: 20px;
}
.threin li::marker {
	color: #3ab8eb;
}
.banleft h1 {
  font-size: 75px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  margin-bottom: 5px !important;
}
.banleft p {
  font-size: 21px;
  color: #fff;
  font-weight: 500;
  line-height: 1.5em;
}
.flip-box-back li {
	font-size: 17px;
	font-weight: 500 !important;
}
.banleft button {
  background: transparent;
  color: #fff;
  font-weight: 600;
  border: 1px solid;
}
.homebtm {
  margin-top: 80px;
}
.homebtm h2 {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 140px;
  line-height: 0.8em !important;
  margin-bottom: 5px !important;
  font-weight: 600;
}
.homebtm h3 {
  text-align: center;
  font-size: 30px;
  color: #8ec899;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 25px;
}
.home.page-id-01 {
  background: #003723;
}
.bgrow02 {
  background: #fff;
  border-top-right-radius: 50px;
}
.homerow02 {
  background: linear-gradient(to bottom, #143, #003723 40%, #003723 60%);
}
.bgrow02 {
	background: #fff;
	border-top-right-radius: 0;
	padding: 60px 20px;
	border-bottom-right-radius: 70px;
}
.bgrow4 {
  padding: 80px 20px;
  background: #fff;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
}
.container.row4text h2 {
  font-size: 70px;
  color: #003723;
  text-transform: uppercase;
}
.homerowabouttext p {
  font-size: 20px;
  color: #575756;
  font-weight: 500;
  margin-bottom: 0;
}
.homerowabouttext h2 {
  font-size: 70px;
  color: #003723;
  text-transform: uppercase;
  margin-bottom: 0;
}
.abfourboxes01 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.mtop20 {
  margin-top: 20px;
}
.abfourboxes01box01 p {
  color: #575756;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 0 !important;
}
.abfourboxes01box01 img {
  margin-bottom: 10px;
}
.homerowabouttext {
  margin-bottom: 40px;
}
.abfourboxes01box01 {
	border: 1px solid #dedede;
	border-radius: 5px;
	padding: 10px 15px;
	background: #fff;
}
.abfourboxes01box01 h3 {
  color: #003723;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 1.2em;
}
.homerow03 {
  background: url(/src/img/home/bgrow3.png);
  background-size: cover;
  padding: 60px 20px;
  background-position: 50% 20%;
  background-repeat: repeat !important;
}
.container.row3text {
  text-align: center;
}
.container.row3text h2 {
  color: #fff;
  font-size: 74px;
  font-weight: 500;
  margin-bottom: 5px;
}
.btn2 {
	background: #0d825f;
	color: #fff;
	font-size: 20px;
	font-weight: 700;
	padding: 12px 35px;
}
.btn2:hover {
  background: #0d825fc4;
}
.scle1 {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.homerow03 {
  position: relative;
}
.container.row3text {
  position: relative;
  z-index: 1;
}
.homerow03::before {
  content: "";
  background: url(/src/img/home/before.png);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 370px;
  height: 244px;
  z-index: 0;
}
.pricb1.priceb2 {
  background: #e1f5ef;
}
.priceboxes01 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.pricb1 {
  flex: 1 1;
  background: #eefcef;
  padding: 35px 15px !important;
  border-radius: 3px;
}
.list01 {
  margin-bottom: 0 !important;
}
.list01 li:last-child {
  margin-bottom: 0 !important;
}
.pricb1 h3 {
  text-align: center;
  font-size: 50px;
  color: #003723;
  margin-bottom: 10px;
}
.pde {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #0c8059;
}
.pr {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #575756;
}
.btn3 {
	background: #003723;
	font-size: 20px;
	padding: 10px 40px;
	font-weight: 700;
	color: #fff;
}
.btn3:hover {
  background: #003723c4;
}
.pricb1 {
  text-align: center;
}
.list01 {
  list-style: none;
  text-align: left;
  padding: 0;
  margin-top: 20px;
}
.list01 {
	max-width: 310px !important;
	margin-left: auto;
	margin-right: auto;
	text-align: center !important;
}
.list01 li {
  font-size: 19px;
  color: #575756;
  font-weight: 500;
  position: relative;
  padding-left: 0px;
  margin-bottom: 13px;
}
.list01 li::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 27px;
  background-image: url("/src/img/home/li_img.png");
  background-repeat: no-repeat;
  background-size: 20px !important;
  top: 15px;
}
.homerow5 {
  padding: 40px 20px;
  padding-top: 60px;
}
.boxlef05 p {
  font-size: 20px;
  color: #479e7e;
  font-weight: 500;
}
.list01 li {
	text-align: center;
}
.list01 li img {
	display: inline-block;
	margin-right: 10px;
	position: relative;
	top: 4px;
}
.boxlef05 h2 {
  font-size: 70px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 5px;
  color: #fff;
}
.homerow6 {
  padding: 20px;
}
.homrrow7 {
  padding: 40px 20px;
}
.scbox {
  background: #82ca9c;
  border-radius: 20px;
  border-bottom-right-radius: 80px;
  display: grid;
  grid-template-columns: 40% 60% !important;
  position: relative;
  overflow: hidden;
}
.scle1 {
  background: url(/src/img/home/left_btm.png);
  background-size: cover;
  background-position: center;
}
.rig {
  max-width: 550px;
  padding: 70px 30px;
  position: relative;
}
.scbox::before {
  content: "";
  background: url(/src/img/home/sc_before.png);
  width: 438px;
  height: 456px;
  position: absolute;
  right: -183px;
  top: 0;
  z-index: 0;
}
.rig h2 {
  font-size: 83px;
  color: #003723;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1;
}
.rig p {
  color: #003723;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4em;
}
.rig h2 > span {
  font-size: 102px;
}
/* video css */
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	background: transparent !important;
	border-radius: 10px !important;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banleft h2 {
	color: #fff;
	text-transform: uppercase;
	font-size: 35px;
  line-height: 1.2em !important;
}
@media (max-width: 767px) {
  .container.homeinner div {
    flex: unset !important;
  }
  .nthree {
    display: grid;
    grid-template-columns: repeat(1,1fr) !important;
    gap: 15px;
  }
  .blocktext::after {
    color: #ffffff21;
    border-left: 20px solid;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -20px;
    top: 0;
  }
  .blocktext {
    margin-right: 25px !important;
    margin-bottom: 10px !important;
  }
  .threecont {
    flex-direction: column !important;
  }
  .banleft h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 28px;

  }
  .pricb1 {
    flex: unset !important;
  }
  .container.homeinner div, .pricb1 {
    flex: auto !important;
  }
  .quote img {
    max-width: 50px;
  }
  .list01 li::before {
    background-size: 20px !important;
    top: 15px;
  }
  .abfourboxes01 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .banleft h1 {
    font-size: 40px;
    margin-bottom: 10px !important;
  }
  .banleft p {
    font-size: 16px;
  }
  button {
    font-size: 15px;
  }
  .homebtm h2 {
    font-size: 40px;
    margin-bottom: 10px !important;
  }
  .homebtm h3 {
    font-size: 22px;
  }
  .homebtm {
    margin-top: 40px;
  }
  .homerowabouttext h2 {
    font-size: 30px;
  }
  .homerowabouttext p {
    font-size: 16px;
  }
  .bgrow02 {
    padding: 30px 20px;
  }
  .abfourboxes01box01 img {
    max-width: 90px;
  }
  .homerowabouttext {
    margin-bottom: 25px;
  }
  .abfourboxes01box01 h3 {
    font-size: 25px;
  }
  .homerow03 {
    padding: 30px 20px;
  }
  .container.row3text h2 {
    font-size: 30px;
    line-height: 1.2em !important;
    margin-bottom: 15px !important;
  }
  .homerow03::before {
    display: none;
  }
  .btn2 {
    font-size: 16px !important;
    padding: 12px 28px;
  }
  .bgrow4 {
    padding: 30px 20px;
  }
  .container.row4text h2 {
    font-size: 30px !important;
    text-align: center;
  }
  .pricb1 h3 {
    text-align: center;
    font-size: 28px;
  }
  .pr {
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px !important;
  }
  .pde {
    text-align: center;
    font-size: 16px;
  }
  .btn3 {
    font-size: 16px;
    padding: 10px 35px;
  }
  .list01 li {
    font-size: 16px;
  }
  .boxlef05 h2 {
    font-size: 30px;
    margin-bottom: 10px;
    text-align: center;
  }
  .boxlef05 p {
    font-size: 16px;
    text-align: center;
  }
  .testcontent p {
    font-size: 16px !important;
  }
  .homerow5 {
    padding: 30px 20px;
    padding-top: 30px;
  }
  .homrrow7 {
    padding: 30px 20px;
  }
  .scbox {
    grid-template-columns: 100% !important;
  }
  .scle1 {
    min-height: 250px;
    background-position: center;
    border-bottom-left-radius: 0 !important;
  }
  .scbox::before {
    display: none;
  }
  .rig {
    max-width: 100%;
    padding: 30px !important;
    position: relative;
  }
  .rig h2 {
    font-size: 30px;
    margin-bottom: 10px !important;
    text-align: center;
  }
  .rig h2 > span {
    font-size: 40px;
  }
  .rig p {
    font-size: 16px;
    text-align: center;
  }
  .pde.pde0 {
    display: none !important;
  }
  .rig {
    max-width: 100%;
    padding: 30px !important;
    position: relative;
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .abfourboxes01 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .banleft h1 {
    font-size: 50px !important;
  }
  body .feature-box.hover-effect:last-child {
    margin-left: 0 !important;
  }
  .banleft p {
    font-size: 18px !important;
  }
  .homebtm h2 {
    font-size: 50px;
  }
  .homebtm h3 {
    text-align: center;
    font-size: 24px;
  }
  .homerowabouttext h2 {
    font-size: 45px !important;
  }
  .homerowabouttext p {
    font-size: 17px;
  }
  .abfourboxes01box01 {
    padding: 10px !important;
  }
  .abfourboxes01box01 img {
    max-width: 90px !important;
  }
  .container.row3text h2 {
    font-size: 50px;
    margin-bottom: 10px;
  }
  .btn2 {
    font-size: 17px;
    padding: 12px 30px;
  }
  .container.row4text h2 {
    font-size: 50px;
  }
  .pricb1 h3 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .pr {
    text-align: center;
    font-size: 19px;
    margin-bottom: 14px;
  }
  .pde {
    text-align: center;
    font-size: 17px;
  }
  .btn3 {
    font-size: 17px;
    padding: 10px 35px;
  }
  .list01 li {
    font-size: 17px;
  }
  .boxlef05 {
    max-width: 650px !important;
  }
  .boxlef05 h2 {
    font-size: 50px;
  }
  .boxlef05 p {
    font-size: 17px;
  }
  .quote img {
    max-width: 65px;
  }
  .testcontent p {
    font-size: 17px !important;
  }
  .rig h2 {
    font-size: 45px;
  }
  .rig h2 > span {
    font-size: 65px;
  }
  .scbox::before {
    display: none !important;
  }
  .rig {
    padding: 50px 30px;
    position: relative;
  }
  .rig p {
    color: #003723;
    font-size: 17px;
  }
  .foocol {
    padding: 30px 20px;
  }
  .footer figure img {
    max-width: 230px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .banleft h1 {
    font-size: 70px;
  }
  .banleft p {
    font-size: 20px;
  }
  .homebtm h2 {
    font-size: 70px;
    line-height: 1 !important;
  }
  .homerowabouttext h2 {
    font-size: 55px;
  }
  .homerowabouttext p {
    font-size: 18px;
  }
  .abfourboxes01box01 img {
    max-width: 100px;
  }
  .container.row3text h2 {
    font-size: 60px;
  }
  .btn2 {
    font-size: 17px;
  }
  .container.row4text h2 {
    font-size: 60px;
  }
  .pricb1 h3 {
    font-size: 45px;
  }
  .pr {
    text-align: center;
    font-size: 20px;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
  }
  .btn3 {
    font-size: 17px;
  }
  .list01 li {
    font-size: 18px;
  }
  .boxlef05 h2 {
    font-size: 60px;
  }
  .boxlef05 p {
    font-size: 19px;
  }
  .boxlef05 {
    max-width: 91%;
  }
  .ipad {
    padding: 15px !important;
    margin: 0 !important;
  }
  .quote img {
    max-width: 70px !important;
  }
  .rig h2 {
    font-size: 70px !important;
  }
  .rig h2 > span {
    font-size: 90px;
  }
  .rig p {
    font-size: 19px;
  }
}
@media (max-width: 1300px) {
  .scbox::before {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .logo-image {
    max-width: 230px !important;
  }
}
@media(max-width:767px){
    body .abfourboxes01box01 img {
	max-width: unset !important;
}
body .dropdown-list {
	padding: 15px !important;
}
}
@media (min-width: 768px) and (max-width: 1024px) {
body .abfourboxes01box01 img {
    max-width: unset !important;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
body .abfourboxes01box01 img {
    max-width: unset !important;
  }
}